import APIReq from "../_APIReq";
import { API_URL, API_COMMON_ERROR_MSG } from "../../plugins/Const";

const api = {
    request: async(fileNo) => {
        let retData = {
            isSuccess: false,
            errorMsg: '',
            spine: null,
            hands: null,
            status: 200,
        };
        try {
            const res = await APIReq(`${API_URL}/reading/result/`+fileNo, 'post');
            if (res && res.data && res.data.result) {          
                retData.isSuccess = true;
                retData.spine = res.data.spine;
                retData.hands = res.data.hands;
            } else if (res && res.data && res.data.error) {
                retData.isSuccess = false;
                retData.errorMsg = res.data.error;
            } else {
                retData.isSuccess = false;
                retData.errorMsg = API_COMMON_ERROR_MSG;
            }
        } catch (e) {
            retData.isSuccess = false;
            retData.status = e.response.status;
            retData.errorMsg = e.response.data.message ?? API_COMMON_ERROR_MSG;
        }
        return retData;
    }
};
export default api